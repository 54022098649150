import React, { Suspense, lazy, useEffect } from 'react';
import { Switch, useLocation, Route, useHistory } from 'react-router-dom';
import PageLoader from './PageLoader';
import { connect } from 'react-redux';
import { getLocalStorage } from '../Utils/const';
import { AUTH_DATA } from '../Utils/AUTH_CONST';
import ScrollProgressBar from '../Components/Common/ProgressScrollBar';

const Routes = {
  '/vapt' : lazy(() => import('./VAPT/routes')),
  '/merchant-laundering*': lazy(() => import('./MerchantLaundering/routes')),
  '/investment-scams*': lazy(() => import('./InvestmentScam/routes')),
  '/affiliate-monitoring*': lazy(() => import('./AffiliateMonitoring/routes')),
  '/brand-monitoring*': lazy(() => import('./BrandMonitoring/routes')),
  '/identity-scan*': lazy(() => import('./IdentifyScan/routes')),
  '/file-upload': lazy(() => import('./FileUpload/index')),
  '/user-*': lazy(() => import('./IdScan/routes')),
  '/user*': lazy(() => import('./UserManagement/routes')),
  '/change-password': lazy(() => import('./UserManagement/changepassword')),
  '/id-scan*': lazy(() => import('./IdScan/routes')),
  '/rat*': lazy(() => import('./RAT/routes')),
  '/create-user*': lazy(() => import('./IdScan/routes')),
  '/dark-web*': lazy(() => import('./DarkWeb/routes')),
  '/brand-infringement*': lazy(() => import('./BrandInfringement/index')),
  '/lea-dashboard': lazy(() => import('./LeaDashboard/index')),
  '/lea/dashboard': lazy(() => import('./LeaDashboard2/Dashboard/index')),
  '/screenshot*': lazy(() => import('./ScreenshotConfigReport/index')),
  '/*': lazy(() => import('./InputConfig/routes')),
};

export function AppPages() {
  useValidatePath();
  return (
    <>
      <ScrollProgressBar />
      <Suspense fallback={<PageLoader />}>
        <Switch>
          {Object.entries(Routes).map(([k, v]) => (
            <Route key={k} path={k} component={v} />
          ))}
          <Route path='/*' element={<p>404</p>} />
        </Switch>
      </Suspense>
    </>
  );
}

const mapStateToProps = (state) => {
  const { common } = state;
  return {
    menuLists: common && common?.menu_list ? common.menu_list?.menus : []
  };
};

export default connect(mapStateToProps)(AppPages);

function useValidatePath() {
  const history = useHistory();
  const isAuthorized = JSON.parse(getLocalStorage(AUTH_DATA.AUTH));
  const pathName = useLocation().pathname;

  const getMenuList = getLocalStorage('MENU_LIST_VALUE');
  const menuList = getMenuList !== 'undefined' ? JSON.parse(getMenuList) : [];

  const getMenuRoute = (arr) => {
    if (Array.isArray(arr)) return arr.map((v) => v.route);
    return [];
  };
  const checkValid = (path) => {
    for (const e of getMenuRoute(menuList)) {
      if (path.split('/').at(1) === 'change-password') return true;
      if (path.split('/').at(1) === e.split('/').at(1)) return true;
    }
    return false;
  };

  useEffect(() => {
    if (isAuthorized && !checkValid(pathName))
      history.replace(menuList[0]?.route);
  }, [pathName, menuList]);
}
