import React, { useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux'; // need to check
import { FetchLoginIncidents } from '../../../../../redux/actions/LoginAction';
import { useDispatch } from 'react-redux';
import { setLocalStorage } from '../../../../../Utils/const';
import { ApiUrl } from '../../../../../redux/helpers/Endpoints';
import jwt_decode from 'jwt-decode';
import { AUTH_DATA } from '../../../../../Utils/AUTH_CONST';
import { useLocation } from 'react-router-dom';
import { LOGIN_INCIDENTS_SUCCESS } from '../../../../../redux/constants/LoginConstants';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { SvgIcon } from '@material-ui/core';
import PurpleLogo from '../asset/mFilterIt_Logo _Square_Main.png';
import { Link } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { set } from 'lodash';

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

function Login(props) {
  const [FAILED_LOGIN_ATTEMPT, setFAILED_LOGIN_ATTEMPT] = useState('');
  const [PType, setPType] = useState('password');
  // const [captchaInput, setCaptchaInput] = useState('');
  // const [captchaText, setCaptchaText] = useState('');
  // const [captchaImage, setCaptchaImage] = useState('');
  const [turnstileToken, setTurnstileToken] = useState('');
  const [turnstileError, setTurnstileError] = useState('');
  const [turnstileLoaded, setTurnstileLoaded] = useState(false);
  
  // Generate random captcha text
  // const generateCaptchaText = () => {
  //   const chars = 'ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnpqrstuvwxyz23456789';
  //   let result = '';
  //   for (let i = 0; i < 6; i++) {
  //     result += chars.charAt(Math.floor(Math.random() * chars.length));
  //   }
  //   return result;
  // };

  // Generate captcha image
  // const generateCaptcha = () => {
  //   const canvas = document.createElement('canvas');
  //   const ctx = canvas.getContext('2d');
  //   canvas.width = 200;
  //   canvas.height = 60;

  //   // Fill background
  //   ctx.fillStyle = '#f0f0f0';
  //   ctx.fillRect(0, 0, canvas.width, canvas.height);

  //   // Add noise (random dots)
  //   for (let i = 0; i < 50; i++) {
  //     ctx.fillStyle = `rgba(${Math.random() * 255},${Math.random() * 255},${Math.random() * 255},0.5)`;
  //     ctx.beginPath();
  //     ctx.arc(Math.random() * canvas.width, Math.random() * canvas.height, 1, 0, 2 * Math.PI);
  //     ctx.fill();
  //   }

  //   // Add lines for noise
  //   for (let i = 0; i < 4; i++) {
  //     ctx.strokeStyle = `rgba(${Math.random() * 255},${Math.random() * 255},${Math.random() * 255},0.5)`;
  //     ctx.beginPath();
  //     ctx.moveTo(Math.random() * canvas.width, Math.random() * canvas.height);
  //     ctx.lineTo(Math.random() * canvas.width, Math.random() * canvas.height);
  //     ctx.stroke();
  //   }

  //   // Generate and draw text
  //   const text = generateCaptchaText();
  //   ctx.font = 'bold 32px Arial';
  //   ctx.fillStyle = '#333';
  //   ctx.textBaseline = 'middle';
  //   ctx.textAlign = 'center';
    
  //   // Draw each character with slight rotation
  //   const chars = text.split('');
  //   chars.forEach((char, i) => {
  //     const x = (canvas.width / 6) * (i + 0.5);
  //     const y = canvas.height / 2;
  //     ctx.save();
  //     ctx.translate(x, y);
  //     ctx.rotate((Math.random() - 0.5) * 0.4);
  //     ctx.fillText(char, 0, 0);
  //     ctx.restore();
  //   });

  //   setCaptchaText(text);
  //   setCaptchaImage(canvas.toDataURL());
  // };

  // // Initialize captcha on component mount
  // useEffect(() => {
  //   generateCaptcha();
  // }, []);

  // const validateCaptcha = () => {
  //   return captchaInput.trim() === captchaText;
  // };

  // const handleCaptchaInputChange = (e) => {
  //   setCaptchaInput(e.target.value);
  // };

  const PARAMS = useQuery();
  const { loginData } = props;
  const handleEyeToggle = () => {
    if (PType === 'password') {
      setPType('text');
    } else {
      setPType('password');
    }
  };

  const loginMessage = loginData && loginData.message ? loginData.message : '';
  const loginErrorMessage = loginData && loginData.error ? loginData.error : '';
  const loginStatus = loginData && loginData.status ? loginData.status : '';
  const token =
    loginData && loginData?.token && loginData?.token ? loginData?.token : '';

  const [loading, setLoading] = useState(false);
  const [invalidCred, setInvalidCred] = useState('');
  const dispatch = useDispatch();

  let date = new Date();
  let year = date.getFullYear();

  const initialValues = {
    email: '',
    password: ''
  };

  useEffect(() => {
    const token = PARAMS.get('token');
    console.log(token);
    if (token) {
      dispatch({
        type: LOGIN_INCIDENTS_SUCCESS,
        response: { token, status: true, message: 'Login success' }
      });
    }
  }, [PARAMS]);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Email is required'),
    password: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(' No password provided.')
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }
    return '';
  };

  const fetchPackage = async () => {
    let packageURL = `${ApiUrl}/user-management/packages`;
    const token = localStorage.getItem('token');
    const headers = {
      Accept: '*/*',
      Authorization: token,
      'Content-Type': 'application/json'
    };
    fetch(packageURL, { headers })
      .then(async (response) => {
        if (response.ok) {
          const data = await response.json();
          localStorage.setItem('dpackage', data[0]?.package_name);
          window.location = '/affiliate-monitoring/dashboard';
        } else {
          return Promise.reject(response.status);
        }
      })
      .catch((error) => {
        console.error('There was an error!', error);
      });
  };

  useEffect(() => {
    if (loginStatus && loginMessage === 'Login success') {
      if (token) {
        const decoded = jwt_decode(token);
        setLocalStorage(AUTH_DATA.AUTH, JSON.stringify(decoded));
        localStorage.setItem('token', token);
      }
      fetchPackage();
    } else if (!loginData.status && loginData.error && loginData.error.message)
      setInvalidCred(loginData.error.message);
  }, [loginData]);

  useEffect(() => {
    if (loginErrorMessage === 'Invalid email or password') {
      setInvalidCred(loginErrorMessage);
      if (loginData.loginAttemptLeft)
        setFAILED_LOGIN_ATTEMPT(
          'Remaining login attempt(s) left ' + loginData.loginAttemptLeft
        );
    }
  }, [loginErrorMessage]);

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {

      if (!turnstileToken) {
        setTurnstileError('Please complete the verification');
        setSubmitting(false);
        return;
      }
      let {email,password} = values
      values = { email, password: password?.trim() }

      setTimeout(() => {
        dispatch(FetchLoginIncidents(values));
        disableLoading();
        setSubmitting(false);
      }, 1000);
    }
  });

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://challenges.cloudflare.com/turnstile/v0/api.js';
    script.async = true;
    script.defer = true;
    script.onload = () => {
      setTurnstileLoaded(true);
      // Initialize Turnstile after script loads
      if (window.turnstile) {
        window.turnstile.render('#turnstile-widget', {
          sitekey: '0x4AAAAAABBXNTa5a18XM-kR',
          callback: (token) => {
            setTurnstileToken(token);
            setTurnstileError('');
          },
          'error-callback': () => {
            setTurnstileError('Failed to load Turnstile. Please try again.');
          }
        });
      }
    };
    script.onerror = () => {
      setTurnstileError('Failed to load Turnstile. Please try again.');
    };
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
      if (window.turnstile) {
        window.turnstile.remove('#turnstile-widget');
      }
    };
  }, []);

  return (
    <>
    <Toaster position='top-right' />
    <div
      className='d-flex justify-content-between flex-column align-items-center login-form login-signin h-100 text-align-center'
      id='kt_login_signin_form'
    >
      <Link to='/' className='flex-column-auto mt-5 pb-lg-0 pb-10'>
        <img alt='Logo' className='max-h-120px mt-15' src={PurpleLogo} />
      </Link>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit(e);
        }}
        className='d-flex flex-column align-items-center justify-content-center form fv-plugins-bootstrap fv-plugins-framework mb-20'
      >
        <div className='form-group fv-plugins-icon-container text-center'>
          {invalidCred.length !== 0 ? (
            <>
              <h6 className='text-danger mb-5 text-center font-weight-bold'>
                {invalidCred}
              </h6>
              <h8 className='text-danger mb-5 text-center'>
                {FAILED_LOGIN_ATTEMPT}
              </h8>
            </>
          ) : null}
          <input
            placeholder='Email'
            type='email'
            className={`form-control form-control-solid h-auto px-6 ${getInputClasses(
              'email'
            )}`}
            name='email'
            onKeyUp={() => {
            }}
            style={{
              width: '400px',
              border: '1px solid grey',
              fontFamily: 'poppins'
            }}
            {...formik.getFieldProps('email')}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className='form-group fv-plugins-icon-container'>
          <div style={{ position: 'relative' }}>
            <input
              placeholder='Password'
              type={PType}
              className={`form-control form-control-solid h-auto px-6 ${getInputClasses(
                'password'
              )}`}
              onKeyUp={() => {
              }}
              name='password'
              style={{
                width: '400px',
                border: '1px solid grey',
                fontFamily: 'poppins'
              }}
              {...formik.getFieldProps('password')}
            />
            <span
              className='icontag'
              style={{
                position: 'absolute',
                right: '10%',
                transform: 'translate(0, -50%)',
                top: '50%'
              }}
              onClick={handleEyeToggle}
            >
              <SvgIcon
                component={PType === 'text' ? VisibilityOff : Visibility}
              />
            </span>
          </div>
          {formik.touched.password && formik.errors.password ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <div className='form-group fv-plugins-icon-container'>
              <div className='turnstile-container text-center mb-3'>
                <div id="turnstile-widget"></div>
                {turnstileError && (
                  <div className="text-danger mt-2">{turnstileError}</div>
                )}
              </div>
            </div>

        <div className='form-group d-flex flex-wrap justify-content-between align-items-center'>
          <button
            id='kt_login_signin_submit'
            type='submit'
            disabled={formik.isSubmitting || !turnstileToken}
            className={`btn btn-primary font-weight-bold px-9 my-3`}
            style={{
              backgroundImage: 'linear-gradient(indigo,purple,purple)',
              width: '200px'
            }}
          >
            <span
              style={{
                fontSize: '15px',
                fontWeight: 'bold',
                fontFamily: 'poppins'
              }}
            >
              LOGIN
            </span>
            {loading && <span className='ml-3 spinner spinner-white'></span>}
          </button>
        </div>
      </form>
      <div
        className='d-none flex-column-auto d-lg-flex justify-content-between mb-3'
        style={{ width: '450px' }}
      >
        <a
          target='_blank'
          rel='noopener noreferrer'
          href='https://www.mfilterit.com/contact-us'
          className='text-black'
          style={{
            textDecoration: 'underline',
            color: 'black',
            fontFamily: 'poppins'
          }}
        >
          Contact
        </a>
        <div>
          &copy;{' '}
          <a
            className='font-weight-bold text-black'
            target='_blank'
            rel='noopener noreferrer'
            href='https://www.mfilterit.com/'
            style={{ color: 'black', fontFamily: 'poppins' }}
          >
            {year} mFilterIt
          </a>
        </div>
        <a
          target='_blank'
          rel='noopener noreferrer'
          href='https://www.mfilterit.com/about-us'
          className='text-black'
          style={{
            textDecoration: 'underline',
            color: 'black',
            fontFamily: 'poppins'
          }}
        >
          About Us
        </a>
      </div>
    </div>
    </>
  );
}

const mapStateToProps = (state) => {
  const { login } = state;
  return {
    loginData: login && login.login_data ? login.login_data : {}
  };
};

export default connect(mapStateToProps)(Login);