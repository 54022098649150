import React from 'react';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import { checkIsActive } from '../Template/_helpers';
import { useSelector, connect } from 'react-redux';
import {
  MdSpaceDashboard,
  MdSummarize,
  MdCampaign,
  MdOutlinePages,
  MdOutlineComputer,
  MdPayment,
  MdOutlineAddAlert
} from 'react-icons/md';
import { RiCoupon2Fill } from 'react-icons/ri';
import {
  TbReport,
  TbReportAnalytics,
  TbListDetails,
  TbAffiliate,
  TbCapture
} from 'react-icons/tb';
import { TfiDashboard } from 'react-icons/tfi';
import {
  AiOutlineCloudUpload,
  AiOutlineCloudDownload,
  AiOutlineReconciliation,
  AiFillSafetyCertificate,
  AiOutlineUser
} from 'react-icons/ai';
import { FaMobileAlt } from 'react-icons/fa';
import { VscSymbolKeyword } from 'react-icons/vsc';
import { SiAffinitypublisher } from 'react-icons/si';
import { HiClipboard } from 'react-icons/hi';
import './menu-icons.scss';

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          'menu-item-active'} menu-item-open menu-item-not-hightlighted`
      : '';
  };

  const menuList = useSelector((state) => state?.common?.menu_list?.menus);

  let showbrandkeywordbidding = false;
  let showbrandsafetyDashboard = false;
  let showbrandsafetyDownload = false;
  let showadwordCompetitor = false;
  let showBrandBiddingCoupon = false;
  let showbrandkeywordbiddingDownload = false;
  let identityMenu = false;
  let Usermanagement = false;
  let showMerchantLaunderingMenu = false;
  let showshowFileUploadMenu = false;
  let showInvestmentScams = false;
  let showBiMenu = false;
  let showBMmenu = false;
  let showInputConfig = false;
  let showIDScanMenu = false;
  let showDarkWebMenu = false;
  let showRATMenu = false;
  let showInvestmentScamMenu = false;
  let showVAPTMenu = false;

  let appRouteName = '';

  menuList &&
    menuList.forEach((menu) => {
      if (menu.sub_menu === '7') {
        identityMenu = true;
      } else if (menu.sub_menu === '5') {
        showMerchantLaunderingMenu = true;
      } else if  (menu.sub_menu === '15') {
        showshowFileUploadMenu = true;
      } else if (menu.sub_menu === '10') {
        showInvestmentScams = true;
      } else if (menu.sub_menu === '6') {
        showBiMenu = true;
      } else if (menu.sub_menu === '9') {
        Usermanagement = true;
      } else if (menu.sub_menu === '2') {
        showBMmenu = true;
      } else if (menu.sub_menu === '1') {
        showIDScanMenu = true;
      } else if (menu.sub_menu === '8') {
        showDarkWebMenu = true;
      } else if (menu.sub_menu === '3') {
        showInputConfig = true;
      } else if (menu.sub_menu === '11') {
        showRATMenu = true;
      } else if (menu.sub_menu === '10') {
        showInvestmentScamMenu = true;
      } else if (menu.sub_menu === '13') {
        showVAPTMenu = true;
      }
      appRouteName = menu.route;
      return;
    });

  return (
    <>
      <ul
        className={`menu-nav ${layoutProps.ulClasses}`}
        style={{ cursor: 'pointer' }}
      >
        {showbrandkeywordbidding ? (
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              appRouteName,
              true
            )}`}
            style={{ cursor: 'pointer' }}
            aria-haspopup='true'
            data-menu-toggle='hover'
          >
            <NavLink className='menu-link menu-toggle' to='/error'>
              <span
                className='svg-icon menu-icon'
                style={{ color: 'var(--iconcolor) !important' }}
              >
                <VscSymbolKeyword />
              </span>
              <span className='menu-text'>Brand Keyword Bidding</span>
              <i className='menu-arrow' />
            </NavLink>
            {showbrandkeywordbidding ? (
              <div className='menu-submenu'>
                <i className='menu-arrow' />
                <ul className='menu-subnav' style={{ cursor: 'pointer' }}>
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      appRouteName,
                      true
                    )}`}
                    style={{ cursor: 'pointer' }}
                    aria-haspopup='true'
                  >
                    <NavLink
                      className='menu-link menu-toggle'
                      to='/adword_affiliate'
                    >
                      <span className='svg-icon menu-icon'>
                        {/* <SVG
                          src={toAbsoluteUrl(
                            "https://infringementportalcontent.mfilterit.com/images/media/svg/icons/Home/Library.svg"
                          )}
                        /> */}
                      </span>
                      <h5 className='iconic'>
                        <TbAffiliate />
                      </h5>
                      <span className='menu-text'>Adword Affiliate</span>
                      {/* <i className='menu-arrow' /> */}
                    </NavLink>
                  </li>
                </ul>
              </div>
            ) : null}
            {showadwordCompetitor ? (
              <div className='menu-submenu'>
                <i className='menu-arrow' />
                <ul className='menu-subnav' style={{ cursor: 'pointer' }}>
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      appRouteName,
                      true
                    )}`}
                    aria-haspopup='true'
                    style={{ cursor: 'pointer' }}
                  >
                    <NavLink
                      className='menu-link menu-toggle'
                      to='/adword_competitor'
                    >
                      <span className='svg-icon menu-icon'>
                        {/* <SVG
                          src={toAbsoluteUrl(
                            "https://infringementportalcontent.mfilterit.com/images/media/svg/icons/Home/Library.svg"
                          )}
                        /> */}
                      </span>
                      <h5 className='iconic'>
                        <MdSpaceDashboard />
                      </h5>
                      <span className='menu-text'>Adword Competitor</span>
                      {/* <i className='menu-arrow' /> */}
                    </NavLink>
                    {/* {menuList &&
                      menuList?.map((menu, i) =>
                        menu.sub_menu === '132' ? (
                          <div className='menu-submenu'>
                            <i className='menu-arrow' />
                            <ul className='menu-subnav'>
                              <li
                                className={`menu-item ${getMenuItemActive(
                                  menu.route
                                )}`}
                                aria-haspopup='true'>
                                <NavLink
                                  style={{ marginLeft: '20px' }}
                                  className='menu-link'
                                  to={menu.route}>
                                  <i className='menu-bullet menu-bullet-dot'>
                                    <span />
                                  </i>
                                  <span className='menu-text'>
                                    {menu.menu_name}
                                  </span>
                                </NavLink>
                              </li>
                            </ul>
                          </div>
                        ) : null
                      )} */}
                  </li>
                </ul>
              </div>
            ) : null}
            {showBrandBiddingCoupon ? (
              <div className='menu-submenu'>
                <i className='menu-arrow' />
                <ul className='menu-subnav' style={{ cursor: 'pointer' }}>
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      appRouteName,
                      true
                    )}`}
                    style={{ cursor: 'pointer' }}
                    aria-haspopup='true'
                  >
                    <NavLink className='menu-link menu-toggle' to='/coupons'>
                      <span className='svg-icon menu-icon'>
                        {/* <SVG
                          src={toAbsoluteUrl(
                            "https://infringementportalcontent.mfilterit.com/images/media/svg/icons/Home/Library.svg"
                          )}
                        /> */}
                      </span>
                      <h5 className='iconic'>
                        <RiCoupon2Fill />
                      </h5>
                      <span className='menu-text'>Coupons Affiliate</span>
                      {/* <i className='menu-arrow' /> */}
                    </NavLink>
                    {/* {menuList &&
                      menuList?.map((menu, i) =>
                        menu.sub_menu === '132' ? (
                          <div className='menu-submenu'>
                            <i className='menu-arrow' />
                            <ul className='menu-subnav'>
                              <li
                                className={`menu-item ${getMenuItemActive(
                                  menu.route
                                )}`}
                                aria-haspopup='true'>
                                <NavLink
                                  style={{ marginLeft: '20px' }}
                                  className='menu-link'
                                  to={menu.route}>
                                  <i className='menu-bullet menu-bullet-dot'>
                                    <span />
                                  </i>
                                  <span className='menu-text'>
                                    {menu.menu_name}
                                  </span>
                                </NavLink>
                              </li>
                            </ul>
                          </div>
                        ) : null
                      )} */}
                  </li>
                </ul>
              </div>
            ) : null}

            {showbrandkeywordbiddingDownload ? (
              <div className='menu-submenu'>
                <i className='menu-arrow' />
                <ul className='menu-subnav' style={{ cursor: 'pointer' }}>
                  <li
                    style={{ cursor: 'pointer' }}
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      appRouteName,
                      true
                    )}`}
                    aria-haspopup='true'
                  >
                    <NavLink
                      className='menu-link menu-toggle'
                      to='/keyword-bidding-download'
                    >
                      <span className='svg-icon menu-icon'>
                        {/* <SVG
                          src={toAbsoluteUrl(
                            "https://infringementportalcontent.mfilterit.com/images/media/svg/icons/Home/Library.svg"
                          )}
                        /> */}
                      </span>
                      <h5 className='iconic'>
                        <AiOutlineCloudDownload />
                      </h5>
                      <span className='menu-text'>Download</span>
                      {/* <i className='menu-arrow' /> */}
                    </NavLink>
                    {/* {menuList &&
                      menuList?.map((menu, i) =>
                        menu.sub_menu === '132' ? (
                          <div className='menu-submenu'>
                            <i className='menu-arrow' />
                            <ul className='menu-subnav'>
                              <li
                                className={`menu-item ${getMenuItemActive(
                                  menu.route
                                )}`}
                                aria-haspopup='true'>
                                <NavLink
                                  style={{ marginLeft: '20px' }}
                                  className='menu-link'
                                  to={menu.route}>
                                  <i className='menu-bullet menu-bullet-dot'>
                                    <span />
                                  </i>
                                  <span className='menu-text'>
                                    {menu.menu_name}
                                  </span>
                                </NavLink>
                              </li>
                            </ul>
                          </div>
                        ) : null
                      )} */}
                  </li>
                </ul>
              </div>
            ) : null}
          </li>
        ) : null}

        {showbrandsafetyDashboard ? (
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              appRouteName,
              true
            )}`}
            style={{ cursor: 'pointer' }}
            aria-haspopup='true'
            data-menu-toggle='hover'
          >
            <NavLink className='menu-link menu-toggle' to='/error'>
              <span
                className='svg-icon menu-icon'
                style={{ color: 'var(--iconcolor) !important' }}
              >
                <AiFillSafetyCertificate />
              </span>
              <span className='menu-text'>Brand Safety</span>
              <i className='menu-arrow' />
            </NavLink>
            {showbrandsafetyDashboard ? (
              <div className='menu-submenu'>
                <i className='menu-arrow' />
                <ul className='menu-subnav' style={{ cursor: 'pointer' }}>
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      appRouteName,
                      true
                    )}`}
                    style={{ cursor: 'pointer' }}
                    aria-haspopup='true'
                  >
                    <NavLink
                      className='menu-link menu-toggle'
                      to='/bs-dashboard'
                    >
                      <span className='svg-icon menu-icon'>
                        {/* <SVG
                          src={toAbsoluteUrl(
                            "https://infringementportalcontent.mfilterit.com/images/media/svg/icons/Home/Library.svg"
                          )}
                        /> */}
                      </span>
                      <h5 className='iconic'>
                        <MdSpaceDashboard />
                      </h5>
                      <span className='menu-text'>Dashboard</span>
                      {/* <i className='menu-arrow' /> */}
                    </NavLink>
                  </li>
                </ul>
              </div>
            ) : null}
            {showbrandsafetyDownload ? (
              <div className='menu-submenu'>
                <i className='menu-arrow' />
                <ul className='menu-subnav' style={{ cursor: 'pointer' }}>
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      appRouteName,
                      true
                    )}`}
                    style={{ cursor: 'pointer' }}
                    aria-haspopup='true'
                  >
                    <NavLink
                      className='menu-link menu-toggle'
                      to='/bs-download'
                    >
                      <span className='svg-icon menu-icon'>
                        {/* <SVG
                          src={toAbsoluteUrl(
                            "https://infringementportalcontent.mfilterit.com/images/media/svg/icons/Home/Library.svg"
                          )}
                        /> */}
                      </span>
                      <h5 className='iconic'>
                        <AiOutlineCloudDownload />
                      </h5>
                      <span className='menu-text'>Download</span>
                      {/* <i className='menu-arrow' /> */}
                    </NavLink>
                    {/* {menuList &&
                      menuList?.map((menu, i) =>
                        menu.sub_menu === '132' ? (
                          <div className='menu-submenu'>
                            <i className='menu-arrow' />
                            <ul className='menu-subnav'>
                              <li
                                className={`menu-item ${getMenuItemActive(
                                  menu.route
                                )}`}
                                aria-haspopup='true'>
                                <NavLink
                                  style={{ marginLeft: '20px' }}
                                  className='menu-link'
                                  to={menu.route}>
                                  <i className='menu-bullet menu-bullet-dot'>
                                    <span />
                                  </i>
                                  <span className='menu-text'>
                                    {menu.menu_name}
                                  </span>
                                </NavLink>
                              </li>
                            </ul>
                          </div>
                        ) : null
                      )} */}
                  </li>
                </ul>
              </div>
            ) : null}
          </li>
        ) : null}

        {identityMenu ? (
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              appRouteName,
              true
            )}`}
            style={{ cursor: 'pointer' }}
            aria-haspopup='true'
            data-menu-toggle='hover'
          >
            <NavLink className='menu-link menu-toggle' to='/error'>
              <span
                className='svg-icon menu-icon'
                style={{ color: 'var(--iconcolor) !important' }}
              >
                <TbCapture />
              </span>
              <span className='menu-text'>Topical Scan</span>
              <i className='menu-arrow' />
            </NavLink>
            <div className='menu-submenu'>
              <ul className='menu-subnav' style={{ cursor: 'pointer' }}>
                {menuList &&
                  menuList?.map((menu, i) =>
                    menu.sub_menu === '7' ? (
                      <li
                        key={i}
                        className={`menu-item ${getMenuItemActive(menu.route)}`}
                        aria-haspopup='true'
                        style={{ cursor: 'pointer' }}
                      >
                        <NavLink
                          style={{ marginLeft: '0px' }}
                          className='menu-link'
                          to={menu.route}
                        >
                          <i className='menu-bullet menu-bullet-dot'>
                            <span />
                          </i>
                          <span className='menu-text'>{menu.menu_name}</span>
                        </NavLink>
                      </li>
                    ) : null
                  )}
              </ul>
            </div>
          </li>
        ) : null}
        {Usermanagement ? (
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              appRouteName,
              true
            )}`}
            aria-haspopup='true'
            data-menu-toggle='hover'
            style={{ cursor: 'pointer' }}
          >
            <NavLink className='menu-link menu-toggle' to='/error'>
              <span
                className='svg-icon menu-icon'
                style={{ color: 'var(--iconcolor) !important' }}
              >
                <AiOutlineUser />
              </span>
              <span className='menu-text'>User Management</span>
              <i className='menu-arrow' />
            </NavLink>
            <div className='menu-submenu'>
              <ul className='menu-subnav' style={{ cursor: 'pointer' }}>
                {menuList &&
                  menuList?.map((menu, i) =>
                    menu.sub_menu === '9' ? (
                      <li
                        style={{ cursor: 'pointer' }}
                        key={i}
                        className={`menu-item ${getMenuItemActive(menu.route)}`}
                        aria-haspopup='true'
                      >
                        <NavLink
                          style={{ marginLeft: '0px' }}
                          className='menu-link'
                          to={menu.route}
                        >
                          <i className='menu-bullet menu-bullet-dot'>
                            <span />
                          </i>
                          <span className='menu-text'>{menu.menu_name}</span>
                        </NavLink>
                      </li>
                    ) : null
                  )}
              </ul>
            </div>
          </li>
        ) : null}
        {showMerchantLaunderingMenu ? (
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              appRouteName,
              true
            )}`}
            style={{ cursor: 'pointer' }}
            aria-haspopup='true'
            data-menu-toggle='hover'
          >
            <NavLink className='menu-link menu-toggle' to='/error'>
              <span
                className='svg-icon menu-icon'
                style={{ color: 'var(--iconcolor) !important' }}
              >
                <MdSpaceDashboard />
              </span>
              <span className='menu-text'>Transactional Laundering</span>
              <i className='menu-arrow' />
            </NavLink>
            <div className='menu-submenu'>
              <ul className='menu-subnav'>
                {menuList &&
                  menuList?.map((menu, i) =>
                    menu.sub_menu === '5' ? (
                      <li
                        key={i}
                        className={`menu-item ${getMenuItemActive(menu.route)}`}
                        aria-haspopup='true'
                        style={{ cursor: 'pointer' }}
                      >
                        <NavLink
                          style={{ marginLeft: '0px' }}
                          className='menu-link'
                          to={menu.route}
                        >
                          <i className='menu-bullet menu-bullet-dot'>
                            <span />
                          </i>
                          <span className='menu-text'>{menu.menu_name}</span>
                        </NavLink>
                      </li>
                    ) : null
                  )}
              </ul>
            </div>
          </li>
        ) : null}
          {showshowFileUploadMenu ? (
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              appRouteName,
              true
            )}`}
            style={{ cursor: 'pointer' }}
            aria-haspopup='true'
            data-menu-toggle='hover'
          >
            <NavLink className='menu-link menu-toggle' to='/error'>
              <span
                className='svg-icon menu-icon'
                style={{ color: 'var(--iconcolor) !important' }}
              >
                <MdSpaceDashboard />
              </span>
              <span className='menu-text'>File Upload</span>
              <i className='menu-arrow' />
            </NavLink>
            <div className='menu-submenu'>
              <ul className='menu-subnav'>
                {menuList &&
                  menuList?.map((menu, i) =>
                    menu.sub_menu === '15' ? (
                      <li
                        key={i}
                        className={`menu-item ${getMenuItemActive(menu.route)}`}
                        aria-haspopup='true'
                        style={{ cursor: 'pointer' }}
                      >
                        <NavLink
                          style={{ marginLeft: '0px' }}
                          className='menu-link'
                          to={menu.route}
                        >
                          <i className='menu-bullet menu-bullet-dot'>
                            <span />
                          </i>
                          <span className='menu-text'>{menu.menu_name}</span>
                        </NavLink>
                      </li>
                    ) : null
                  )}
              </ul>
            </div>
          </li>
        ) : null}
        {showInvestmentScams ? (
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              appRouteName,
              true
            )}`}
            aria-haspopup='true'
            data-menu-toggle='hover'
            style={{ cursor: 'pointer' }}
          >
            <NavLink className='menu-link menu-toggle' to='/error'>
              <span
                className='svg-icon menu-icon'
                style={{ color: 'var(--iconcolor) !important' }}
              >
                <MdSpaceDashboard />
              </span>
              <span className='menu-text'>Investment Scams</span>
              <i className='menu-arrow' />
            </NavLink>
            <div className='menu-submenu'>
              <ul className='menu-subnav'>
                {menuList &&
                  menuList?.map((menu, i) =>
                    menu.sub_menu === '10' ? (
                      <li
                        key={i}
                        className={`menu-item ${getMenuItemActive(menu.route)}`}
                        aria-haspopup='true'
                        style={{ cursor: 'pointer' }}
                      >
                        <NavLink
                          style={{ marginLeft: '0px' }}
                          className='menu-link'
                          to={menu.route}
                        >
                          <i className='menu-bullet menu-bullet-dot'>
                            <span />
                          </i>
                          <span className='menu-text'>{menu.menu_name}</span>
                        </NavLink>
                      </li>
                    ) : null
                  )}
              </ul>
            </div>
          </li>
        ) : null}
        {showBiMenu ? (
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              appRouteName,
              true
            )}`}
            aria-haspopup='true'
            style={{ cursor: 'pointer' }}
            data-menu-toggle='hover'
          >
            <NavLink className='menu-link menu-toggle' to='/error'>
              <span
                className='svg-icon menu-icon'
                style={{ color: 'var(--iconcolor) !important' }}
              >
                <MdSpaceDashboard />
              </span>
              <span className='menu-text'>Affiliate Monitoring</span>
              <i className='menu-arrow' />
            </NavLink>
            <div className='menu-submenu'>
              <ul className='menu-subnav'>
                {menuList &&
                  menuList?.map((menu, i) =>
                    menu.sub_menu === '6' ? (
                      <li
                        key={i}
                        style={{ cursor: 'pointer' }}
                        className={`menu-item ${getMenuItemActive(menu.route)}`}
                        aria-haspopup='true'
                      >
                        <NavLink
                          style={{ marginLeft: '0px' }}
                          className='menu-link'
                          to={menu.route}
                        >
                          <i className='menu-bullet menu-bullet-dot'>
                            <span />
                          </i>
                          <span className='menu-text'>{menu.menu_name}</span>
                        </NavLink>
                      </li>
                    ) : null
                  )}
              </ul>
            </div>
          </li>
        ) : null}
        {showIDScanMenu ? (
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              appRouteName,
              true
            )}`}
            aria-haspopup='true'
            data-menu-toggle='hover'
            style={{ cursor: 'pointer' }}
          >
            <NavLink className='menu-link menu-toggle' to='/error'>
              <span
                className='svg-icon menu-icon'
                style={{ color: 'var(--iconcolor) !important' }}
              >
                <MdSpaceDashboard />
              </span>
              <span className='menu-text'>ID Scan</span>
              <i className='menu-arrow' />
            </NavLink>
            <div className='menu-submenu'>
              <ul className='menu-subnav' style={{ cursor: 'pointer' }}>
                {menuList &&
                  menuList?.map((menu, i) =>
                    menu.sub_menu === '1' ? (
                      <li
                        key={i}
                        className={`menu-item ${getMenuItemActive(menu.route)}`}
                        aria-haspopup='true'
                        style={{ cursor: 'pointer' }}
                      >
                        <NavLink
                          style={{ marginLeft: '0px' }}
                          className='menu-link'
                          to={menu.route}
                        >
                          <i className='menu-bullet menu-bullet-dot'>
                            <span />
                          </i>
                          <span className='menu-text'>{menu.menu_name}</span>
                        </NavLink>
                      </li>
                    ) : null
                  )}
              </ul>
            </div>
          </li>
        ) : null}
        {showDarkWebMenu ? (
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              appRouteName,
              true
            )}`}
            style={{ cursor: 'pointer' }}
            aria-haspopup='true'
            data-menu-toggle='hover'
          >
            <NavLink className='menu-link menu-toggle' to='/error'>
              <span
                className='svg-icon menu-icon'
                style={{ color: 'var(--iconcolor) !important' }}
              >
                <MdSpaceDashboard />
              </span>
              <span className='menu-text'>Dark Web</span>
              <i className='menu-arrow' />
            </NavLink>
            <div className='menu-submenu'>
              <ul className='menu-subnav'>
                {menuList &&
                  menuList?.map((menu, i) =>
                    menu.sub_menu === '8' ? (
                      <li
                        key={i}
                        style={{ cursor: 'pointer' }}
                        className={`menu-item ${getMenuItemActive(menu.route)}`}
                        aria-haspopup='true'
                      >
                        <NavLink
                          style={{ marginLeft: '0px' }}
                          className='menu-link'
                          to={menu.route}
                        >
                          <i className='menu-bullet menu-bullet-dot'>
                            <span />
                          </i>
                          <span className='menu-text'>{menu.menu_name}</span>
                        </NavLink>
                      </li>
                    ) : null
                  )}
              </ul>
            </div>
          </li>
        ) : null}
        {showBMmenu ? (
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              appRouteName,
              true
            )}`}
            style={{ cursor: 'pointer' }}
            aria-haspopup='true'
            data-menu-toggle='hover'
          >
            <NavLink className='menu-link menu-toggle' to='/error'>
              <span
                className='svg-icon menu-icon'
                style={{ color: 'var(--iconcolor) !important' }}
              >
                <MdSpaceDashboard />
              </span>
              <span className='menu-text'>Keyword Scan</span>
              <i className='menu-arrow' />
            </NavLink>
            <div className='menu-submenu'>
              <ul className='menu-subnav'>
                {menuList &&
                  menuList?.map((menu, i) =>
                    menu.sub_menu === '2' ? (
                      <li
                        key={i}
                        style={{ cursor: 'pointer' }}
                        className={`menu-item ${getMenuItemActive(menu.route)}`}
                        aria-haspopup='true'
                      >
                        <NavLink
                          style={{ marginLeft: '0px' }}
                          className='menu-link'
                          to={menu.route}
                        >
                          <i className='menu-bullet menu-bullet-dot'>
                            <span />
                          </i>
                          <span className='menu-text'>{menu.menu_name}</span>
                        </NavLink>
                      </li>
                    ) : null
                  )}
              </ul>
            </div>
          </li>
        ) : null}
        {showInputConfig ? (
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              appRouteName,
              true
            )}`}
            aria-haspopup='true'
            style={{ cursor: 'pointer' }}
            data-menu-toggle='hover'
          >
            <NavLink className='menu-link menu-toggle' to='/error'>
              <span
                className='svg-icon menu-icon'
                style={{ color: 'var(--iconcolor) !important' }}
              >
                <MdSpaceDashboard />
              </span>
              <span className='menu-text'>Input Configuration</span>
              <i className='menu-arrow' />
            </NavLink>
            <div className='menu-submenu'>
              <ul className='menu-subnav'>
                {menuList &&
                  menuList?.map((menu, i) =>
                    menu.sub_menu === '3' ? (
                      <li
                        key={i}
                        style={{ cursor: 'pointer' }}
                        className={`menu-item ${getMenuItemActive(menu.route)}`}
                        aria-haspopup='true'
                      >
                        <NavLink
                          style={{ marginLeft: '0px' }}
                          className='menu-link'
                          to={menu.route}
                        >
                          <i className='menu-bullet menu-bullet-dot'>
                            <span />
                          </i>
                          <span className='menu-text'>{menu.menu_name}</span>
                        </NavLink>
                      </li>
                    ) : null
                  )}
              </ul>
            </div>
          </li>
        ) : null}
        {showRATMenu ? (
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              appRouteName,
              true
            )}`}
            aria-haspopup='true'
            style={{ cursor: 'pointer' }}
            data-menu-toggle='hover'
          >
            <NavLink className='menu-link menu-toggle' to='/error'>
              <span
                className='svg-icon menu-icon'
                style={{ color: 'var(--iconcolor) !important' }}
              >
                <MdSpaceDashboard />
              </span>
              <span className='menu-text'>RAT</span>
              <i className='menu-arrow' />
            </NavLink>
            <div className='menu-submenu'>
              <ul className='menu-subnav'>
                {menuList &&
                  menuList?.map((menu, i) =>
                    menu.sub_menu === '11' ? (
                      <li
                        key={i}
                        className={`menu-item ${getMenuItemActive(menu.route)}`}
                        aria-haspopup='true'
                        style={{ cursor: 'pointer' }}
                      >
                        <NavLink
                          style={{ marginLeft: '0px' }}
                          className='menu-link'
                          to={menu.route}
                        >
                          <i className='menu-bullet menu-bullet-dot'>
                            <span />
                          </i>
                          <span className='menu-text'>{menu.menu_name}</span>
                        </NavLink>
                      </li>
                    ) : null
                  )}
              </ul>
            </div>
          </li>
        ) : null}
        {showInvestmentScamMenu ? (
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              appRouteName,
              true
            )}`}
            aria-haspopup='true'
            style={{ cursor: 'pointer' }}
            data-menu-toggle='hover'
          >
            <NavLink className='menu-link menu-toggle' to='/error'>
              <span
                className='svg-icon menu-icon'
                style={{ color: 'var(--iconcolor) !important' }}
              >
                <MdSpaceDashboard />
              </span>
              <span className='menu-text'>Investment Scams</span>
              <i className='menu-arrow' />
            </NavLink>
            <div className='menu-submenu'>
              <ul className='menu-subnav'>
                {menuList &&
                  menuList?.map((menu, i) =>
                    menu.sub_menu === '10' ? (
                      <li
                        key={i}
                        className={`menu-item ${getMenuItemActive(menu.route)}`}
                        aria-haspopup='true'
                        style={{ cursor: 'pointer' }}
                      >
                        <NavLink
                          style={{ marginLeft: '0px' }}
                          className='menu-link'
                          to={menu.route}
                        >
                          <i className='menu-bullet menu-bullet-dot'>
                            <span />
                          </i>
                          <span className='menu-text'>{menu.menu_name}</span>
                        </NavLink>
                      </li>
                    ) : null
                  )}
              </ul>
            </div>
          </li>
        ) : null}

        {showVAPTMenu ? (
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              appRouteName,
              true
            )}`}
            aria-haspopup='true'
            style={{ cursor: 'pointer' }}
            data-menu-toggle='hover'
          >
            <NavLink className='menu-link menu-toggle' to='/error'>
              <span
                className='svg-icon menu-icon'
                style={{ color: 'var(--iconcolor) !important' }}
              >
                <MdSpaceDashboard />
              </span>
              <span className='menu-text'>VAPT</span>
              <i className='menu-arrow' />
            </NavLink>
            <div className='menu-submenu'>
              <ul className='menu-subnav'>
                {menuList &&
                  menuList?.map((menu, i) =>
                    menu.sub_menu === '13' ? (
                      <li
                        key={i}
                        className={`menu-item ${getMenuItemActive(menu.route)}`}
                        aria-haspopup='true'
                        style={{ cursor: 'pointer' }}
                      >
                        <NavLink
                          style={{ marginLeft: '0px' }}
                          className='menu-link'
                          to={menu.route}
                        >
                          <i className='menu-bullet menu-bullet-dot'>
                            <span />
                          </i>
                          <span className='menu-text'>{menu.menu_name}</span>
                        </NavLink>
                      </li>
                    ) : null
                  )}
              </ul>
            </div>
          </li>
        ) : null}
      </ul>
    </>
  );
}
const mapStateToProps = () => {};
export default connect(mapStateToProps)(AsideMenuList);